import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useParams } from "react-router-dom";
import HolySprit from "../../../Assets/Images/holy-spirit.svg";
import Evangelization from "../../../Assets/Images/evangelization.svg";
import leadership from "../../../Assets/Images/leadership.svg";
import leader from "../../../Assets/Images/leader.svg";
import parishioner from "../../../Assets/Images/parishioner.svg";
import Matrix from '../../../Components/Matrix/Matrix';
import Discover from '../../../Components/Discover/Discover';

//mui

import { CircularProgress } from '@mui/material';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import reloadIcon from '../../../Assets/Images/reload.svg';
import doubleArrow from '../../../Assets/Images/double-arrow-white.svg';
import priestImg from '../../../Assets/Images/priest.svg';
import MobileFlagHeader from '../../../Components/MobileFlagHeader/MobileFlagHeader';
import LanguageContext from '../../../Store/LanguageContext';
import { translate } from '../../../Components/translate/translate';

import DashboardContext from "../../../Store/DashboardContext";



function DashboardCompare() {

  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();
  const langCtx = useContext(LanguageContext)

  const [currTab, setCurrTab] = useState(0);
  const [open, setOpen] = useState(0);
  const [apiData, setApiData] = useState();
  const [isLoading, setIsLoading] = useState(0)

  const dashboardCtx = useContext(DashboardContext);


  const apiData1 = dashboardCtx.parishAPIData;


  useEffect(() => {
    dashboardCtx.setDashboardId(id)
    fetchResults(id)
  }, [id])


  const getParishCompare = () => {
    fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parishCompare")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setApiData(data.data);
          setIsLoading(2)
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch:", error);
        setIsLoading(2)
      });

  }

  const fetchResults = (id) => {
    setIsLoading(0)
    fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parish", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          dashboardCtx.setParishAPIData(data.data);
          setIsLoading(1)
          getParishCompare();
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch:", error);
        setIsLoading(1)
        getParishCompare();
      });
  };

  console.log("apiDataCompare", apiData)

  const squres = (num) => {
    num = Math.ceil(num / 10);
    const squresList = []
    for (let i = 0; i < num; i++) {
      squresList.push(<div className='squre active-squre' key={i} />)
    }
    for (let i = 0; i < 10 - num; i++) {
      squresList.push(<div className='squre' key={i+num} />)
    }
    return squresList;
  }

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardCompareDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const Boxs = useCallback(() => {
    if (currTab === 0) {
      return (
        <div className='box matrix' style={currTab === 0 && { borderTopLeftRadius: "0px" }}>
          {isLoading < 2 ? (
            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}>
              <CircularProgress style={{ color: '#84161D' }} size={60} />
            </div>
          ) : (
            <div className='le-pa-container'>
              {
              (isLoading < 2) ? (
                <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', maxWidth: "100%", width: "94%" }}>
                  <CircularProgress style={{ color: '#84161D' }} size={60} />
                </div>
              ) : (
                <>
                  {
                    apiData?.leaderCount === 0 ||
                    apiData?.parishionerCount === 0
                     ? (
                    <p style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1rem",
                      paddingBottom: "0",
                      margin: "0"
                    }}>
                      {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                    </p>
                  ) : (
                    <>
                      <div className='head ' style={{ display: windowWidth >= 831 ? 'block' : 'none' }}>
                        <div className='row'>
                          <div className='h-cell ' style={{ width: '65%' }} >
                          </div>

                          <div className='h-cell le-pa-container ' style={{ width: '16%' }} >
                            <div className='lp-container'>
                              <img src={leader} alt="" />
                              <p>{handleTranslate("Leaders", "leaders")} <strong>({apiData1?.counts?.leaders + apiData1?.counts?.priests})</strong></p>
                            </div>
                          </div>
                          <div className='h-cell le-pa-container' style={{ width: '16%' }} >
                            <div className='lp-container'>
                              <img src={parishioner} alt="" />
                              <p>{handleTranslate("Parishioners", "parishioners")} <strong>({apiData1?.counts?.parishioners})</strong></p>
                            </div>
                          </div>
                          <div className='h-cell' style={{ width: '5%' }} ></div>
                        </div>
                      </div>
                      <Matrix title={handleTranslate("Experience of the Holy Spirit", "experience-of-the-holy-spirit")} questions={apiData?.sectionTitles['Experience of the Holy Spirit']} color="green" from='compare' open={open === 0 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 0 : -1)} />
                      <Matrix title={handleTranslate("Asking & Discerning in Faith", "asking-and-discerning-in-faith")} questions={apiData?.sectionTitles['Asking & Discerning in Faith']} color="green" from='compare' open={open === 1 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 1 : -1)} />
                      <Matrix title={handleTranslate("Responding in Faith", "responding-in-faith")} questions={apiData?.sectionTitles['Responding in Faith']} color="green" from='compare'  open={open === 2 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 2 : -1)} />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )
    } else if (currTab === 1) {
      return (
        <div className='box matrix' >
          <div className='leppa-container '>
            {
            isLoading < 2 ? 
            (
              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div>
            ) : (
              (apiData?.leaderCount === 0 || apiData?.parishionerCount === 0) ? (
                <p style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem",
                  paddingBottom: "0",
                  margin: "0"
                }}>
                  {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                </p>
              ) : (
                <>
                  <div className='head ' style={{ display: windowWidth >= 831 ? 'block' : 'none' }}>
                    <div className='row'>
                      <div className='h-cell ' style={{ width: '65%' }} >
                      </div>

                      <div className='h-cell le-pa-container ' style={{ width: '16%' }} >
                        <div className='lp-container'>
                          <img src={leader} alt="" />
                          <p>{handleTranslate("Leaders", "leaders")} <strong>({apiData1?.counts?.leaders + apiData1?.counts?.priests})</strong></p>
                        </div>
                      </div>
                      <div className='h-cell le-pa-container' style={{ width: '16%' }} >
                        <div className='lp-container'>
                          <img src={parishioner} alt="" />
                          <p>{handleTranslate("Parishioners", "parishioners")} <strong>({apiData1?.counts?.parishioners})</strong></p>
                        </div>
                      </div>
                      <div className='h-cell' style={{ width: '5%' }} ></div>
                    </div>
                  </div>
                  <Matrix title={handleTranslate('Resources Aligned', 'resources-aligned')} questions={apiData?.sectionTitles['Resources Aligned']} color="green" from='compare' open={open === 0 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 0 : -1)}/>
                  <Matrix title={handleTranslate('Primacy of Programs', 'primacy-of-programs')} questions={apiData?.sectionTitles['Primacy of Programs']} color="green" from='compare' open={open === 1 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 1 : -1)}/>
                  <Matrix title={handleTranslate('Cultural Reinforcement', 'cultural-reinforcement')} questions={apiData?.sectionTitles['Cultural Reinforcement']} color="green" from='compare' open={open === 2 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 2 : -1)}/>
                </>
              )
            )}
          </div>
        </div>
      )
    } else if (currTab === 2) {
      return (
        <div className='box matrix' >
          <div className='leppa-container '>
            {isLoading < 2 ? 
            (
              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div>
            ) : (
              (apiData?.leaderCount === 0 || apiData?.parishionerCount === 0) ? (
                <p style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem",
                  paddingBottom: "0",
                  margin: "0"
                }}>
                  {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                </p>
              ) : (
                <>
                  <div className='head' style={{ display: windowWidth >= 831 ? 'block' : 'none' }}>
                    <div className='row'>
                      <div className='h-cell ' style={{ width: '65%' }} >
                      </div>
                      <div className='h-cell le-pa-container ' style={{ width: '16%' }} >
                        <div className='lp-container'>
                          <img src={leader} alt="" />
                          <p>{handleTranslate("Leaders", "leaders")} <strong>({apiData1?.counts?.leaders + apiData1?.counts?.priests})</strong></p>
                        </div>
                      </div>
                      <div className='h-cell le-pa-container' style={{ width: '16%' }} >
                        <div className='lp-container'>
                          <img src={parishioner} alt="" />
                          <p>{handleTranslate("Parishioners", "parishioners")} <strong>({apiData1?.counts?.parishioners})</strong></p>
                        </div>
                      </div>
                      <div className='h-cell' style={{ width: '5%' }} ></div>
                    </div>
                  </div>
                  <Matrix title={handleTranslate('Vision', 'vision')} questions={apiData?.sectionTitles['Vision']} color="green" from="compare" open={open === 0 ? "collapse" : ""} setOpen={(isOpen) => setOpen(isOpen ? 0 : -1)} />
                  <Matrix title={handleTranslate('Planning and Priorities', 'planning-and-priorities')} questions={apiData?.sectionTitles['Planning and Priorities']} color="green" from="compare" open={open === 1 ? "collapse" : ""}  setOpen={(isOpen) => setOpen(isOpen ? 1 : -1)} />
                  <Matrix title={handleTranslate('Leading out of a team', 'leading-out-of-a-team')} questions={apiData?.sectionTitles['Leading out of a team']} color="green" from="compare" open={open === 2 ? "collapse" : ""}  setOpen={(isOpen) => setOpen(isOpen ? 2 : -1)} />
                  <Matrix title={handleTranslate('Growing Leadership Skills', 'growing-leadership-skills')} questions={apiData?.sectionTitles['Growing Leadership Skills']} color="green" from="compare" open={open === 3 ? "collapse" : ""}  setOpen={(isOpen) => setOpen(isOpen ? 3 : -1)} />
                </>
              )
            )}
          </div>
        </div>
      )
    }
  }, [currTab, isLoading, windowWidth, 
    apiData, apiData1, open, handleTranslate
  ]);  

  return (
    // <div className='cover'>

    <>
      <div className='dashboard-compare '>
        <>
          {windowWidth <= 831 ? <div className='top-compare-header'>
            <div className='top-content'>

              <img src={reloadIcon} alt='' />
              <h3>161  RESPONDENTS</h3>
              <img src={doubleArrow} alt='' />

            </div>

            <div className='user-type-container'>
              <div className='contents'>
                <img src={priestImg} alt='' />
                <div>
                  <span>1  </span>
                  <p> Priest</p>
                </div>
              </div>
              <div className='contents'>
                <img src={leader} alt='' />
                <div>
                  <span>1  </span>
                  <p>Leaders</p>
                </div>
              </div>
              <div className='contents'>
                <img src={parishioner} alt='' />
                <div>
                  <span>1  </span>
                  <p> Parishioners</p>
                </div>
              </div>

            </div>

          </div> : null
          }
          <div className='cover' style={{ background: 'unset' }}>
            <MobileFlagHeader />
          </div>
          <p className='sub-heading '>{handleTranslate("Compare how your leaders and parishioners might be perceiving your parish.", "compare-heading-info")}</p>

          {windowWidth <= 831 ? <div className='mobile-compare-score'>
            <div className="contents">
              <p>{handleTranslate("Holy Spirit", "holy-spirit")}</p>
              <img src={HolySprit} alt="" />
              <div className='squre-bar'>
                {squres(apiData1?.overallAverage?.holyspiritScore)}
              </div>
            </div>
            <div className="contents">
              <p>{handleTranslate("Evangelization", "evangelization")}</p>
              <img src={Evangelization} alt="" />
              <div className='squre-bar'>
                {squres(apiData1?.overallAverage?.evangelismScore)}
              </div>
            </div>
            <div className="contents">
              <p>{handleTranslate("Leadership", "leadership")}</p>
              <img src={leadership} alt="" />

              <div className='squre-bar'>
                {squres((apiData1?.overallAverage?.leadershipScore))}
              </div>
            </div>
          </div> : null
          }

          {/* {windowWidth >= 831 ? */}
          <>
            <div className='tabs'>
              <div className={`tab ${currTab === 0 && 'active'}`} onClick={() => setCurrTab(0)}>
                <img src={HolySprit} alt="" />
                <div>
                  <p>{handleTranslate("Holy Spirit", "holy-spirit")}</p>
                  <div className='squre-bar' style={{ display: windowWidth >= 831 ? 'flex' : 'none' }}>
                    {squres(apiData1?.overallAverage?.holyspiritScore)}
                  </div>
                </div>
              </div>
              <div className={`tab ${currTab === 1 && 'active'}`} onClick={() => setCurrTab(1)}>
                <img src={Evangelization} alt="" />
                <div>
                  <p>{handleTranslate("Evangelization", "evangelization")}</p>
                  <div className='squre-bar' style={{ display: windowWidth >= 831 ? 'flex' : 'none' }}>
                    {squres(apiData1?.overallAverage?.evangelismScore)}
                  </div>
                </div>
              </div>
              <div className={`tab ${currTab === 2 && 'active'}`} onClick={() => setCurrTab(2)}>
                <img src={leadership} alt="" />
                <div>
                  <p>{handleTranslate("Leadership", "leadership")}</p>
                  <div className='squre-bar' style={{ display: windowWidth >= 831 ? 'flex' : 'none' }}>
                    {squres((apiData1?.overallAverage?.leadershipScore))}
                  </div>
                </div>
              </div>
            </div>
            <div className="box-and-discover-parent">
              {Boxs()}
              <Discover />
            </div>
          </>
          {/* : null
        } */}

          {/* <Discover /> */}
        </>
      </div>

    </>

    // </div>
  )
}

export default DashboardCompare