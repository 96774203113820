import { createContext, useEffect, useState } from 'react';
import { dashboardStyle } from '../Components/htmlRender/htmlDashboard';
import LanguageContext from './LanguageContext';
import { translate } from '../Components/translate/translate';
const DashboardContext = createContext();
const langCtx = createContext(LanguageContext)

export function DashboardContextProvider(props) {
  const [location, setLocation] = useState("0");
  const [pieDataDes, setPieDataDes] = useState(null);
  const [htmlString, setHtmlString] = useState("");
  const [parishAPIData, setParishAPIData] = useState(null);
  const [parishOverviewAPIData, setParishOverviewAPIData] = useState(null);
  const [dashboardId, setDashboardId] = useState("");
  const [isSpin,setIsSpin]=useState(false)
  // Wrap the setHtmlString with a functional update method
  const updateHtmlString = (newHtmlContent) => {
    setHtmlString((prevHtmlString) => prevHtmlString + newHtmlContent);
  };

  
  const onDownloadPDF = async() => {

     const handleTranslate = (text, type) => {
    
        console.log('langCtx.language: ', langCtx.language)
    
        switch (langCtx.language) {
          case "Nederlands": {
            return translate(text, "resultPageDutch", type)
          }
          default: {
            return text;
          }
        }
    
      }

   if ( (dashboardId || "").length > 0 ) {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + dashboardId + "/generateReport", {
        method: "POST",
        headers: {
          "Content-Type": "text/html",
          "Accept-Language": langCtx.language === "Nederlands" ? "nl" : "en",
        },
        body:`
        <!DOCTYPE html>
        <html lang="${langCtx.language === "Nederlands" ? "nl" : "en"}">
          <head>
          ${dashboardStyle}
          </head>
          <body>
          <div class="page cover-page">

          <div class="logo-container">
            <img src="https://40parables-assets.s3.amazonaws.com/logo.png" alt="mw-logo">
          </div>
      
          <div class="sections-images-container">
            <img src="https://40parables-assets.s3.amazonaws.com/holy-spirit.png" alt="holy-spirit">
            <img src="https://40parables-assets.s3.amazonaws.com/evangelization.png" alt="evangelization">
            <img src="https://40parables-assets.s3.amazonaws.com/leadership.png" alt="leadership">
          </div>
      
          <div class="church-details">
            <p class="name">St Patrick Parish-ONeill</p>
            <p class="address">Archdiocese of Omaha</p>
          </div>
      
          <div class="footer">
            <p>Brought to you by</p>
            <img src="https://40parables-assets.s3.amazonaws.com/dr-logo.png" alt="dr-logo">
          </div>
        </div>
            ${htmlString
              .replace("Your parish is Raising the Sails", handleTranslate("Your parish is Raising the Sails", "your-parish-is-raising-the-sails"))
              .replace("Your parish's collective scores", handleTranslate("Your parish's collective scores", "your-parish-collective-score"))
            }
          </body>
        </html>
      `
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const url = await response.json();
      const pdfUrl = await url.reportURL;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Result.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log(htmlString)
      setIsSpin(false)
    } catch (error) {
    }
   }
  }

  const resHtmlString = () => {
    setHtmlString("");
    
  };

  const onAppendHtml = (htmlContent) => {
    return new Promise((resolve, reject) => {
      try {
        console.log(htmlContent,"aman")
        updateHtmlString(htmlContent);
        resolve(htmlContent);
      } catch (error) {
        reject(error);
      }
    });
  };
  const setIsSpin2=(value)=>{
    setIsSpin(value)
  }

  const context = {
    location: location,
    updateLocation: setLocation,
    pieDataDes: pieDataDes,
    updatePieData: setPieDataDes,
    htmlString: htmlString,
    updateHtmlString: updateHtmlString,
    onAppendHtml:onAppendHtml,
    resHtmlString:resHtmlString,
    onDownloadPDF:onDownloadPDF,
    isSpin:isSpin,
    setIsSpin2:setIsSpin2,
    parishAPIData,
    setParishAPIData,
    parishOverviewAPIData,
    setParishOverviewAPIData,
    dashboardId,
    setDashboardId
  };

  return (
    <DashboardContext.Provider value={context}>
      {props.children}
    </DashboardContext.Provider>
  );
}

export default DashboardContext;
