import React, { useContext, useMemo, useEffect, useState, useRef } from 'react';
import AuthContext from '../../Store/AuthContext';
import { useParams, useLocation } from "react-router-dom";
import Overview from '../../Assets/Images/overview.png';
import Compare from '../../Assets/Images/compare.png';
import Resources from "../../Assets/Images/Resources.png";
import Insights from "../../Assets/Images/Insights.png";
import Leader from '../../Assets/Images/leader.svg';
import Priest from "../../Assets/Images/priest.svg";
import Parishioner from "../../Assets/Images/parishioner.svg";
import RefreshResults from "../../Assets/Images/Refresh-Results.png";
import Downloadicon from "../../Assets/Images/DownloadIcon.svg"
import ManuIcon from "../../Assets/Images/hamburger-manu.png";
import BackArrow from "../../Assets/Images/double-arrow-back.png";
import Logout from "../../Assets/Images/Log-Out.png";
import Logo from "../../Assets/Images/logo-left-black-footer.png";
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import LanguageContext from '../../Store/LanguageContext'
;
import DashboardContext from '../../Store/DashboardContext';
import DashboardOverview from '../../Pages/Dashboard/Overview/DashboardOverview';
import DashboardInsights from '../../Pages/Dashboard/Insights/DashboardInsights';
import { translate } from '../translate/translate';
import { resultStyle } from '../htmlRender/htmlResults';
import { CircularProgress } from '@mui/material';
function Sidebar() {
  const authCtx = useContext(AuthContext);
  const langCtx = useContext(LanguageContext);
  const dashboardCtx=useContext(DashboardContext)
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef(null);
  

  const { windowWidth } = useWindowDimensions();
  const [tabSelected, setTabSelected] = useState(0);
  const currentLocation = window.location.pathname;
  const [hide, setHide] = useState(false);
  const [slideClass, setSlideClass] = useState('slide-hide');

  const [showDownloadButton, setShowDownloadButton] = useState('')

  const [renderOverview, setRenderOverview] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState("")
  const [renderInsight1, setRenderInsight1] = useState(false)

  const [myResults, setMyResults] = useState(false)
  const [parishDashboard, setParishDashbaord] = useState(false)
  const [leadershipDashboard, setLeadershipDashboard] = useState(false)
  const [parishionerDashBoard, setParishionerDashBoard] = useState(false)
  const [insights, setInsights] = useState(false)
  const [downloadAll, setdownloadAll] = useState(false)
  const [htmlContent, setHtmlContent] = useState('');
  

  const [openPopup, setOpenPopup] = useState(false);

  const apiData = dashboardCtx.parishAPIData;
  const id = dashboardCtx.dashboardId;

  const handleOpen = () => setOpenPopup(true);
  const handleClose = () => setOpenPopup(false);

   useEffect(() => {
      if (componentRef.current) {
        setHtmlContent(componentRef.current.innerHTML);
        console.log(htmlContent)
      }
    });

  const onLabelClick = (label) => {
    return new Promise((resolve) => {
      console.log(label);
      setSelectedLabel(label);
      setRenderOverview(true);

      const interval = setInterval(() => {
        if (!renderOverview) {
          clearInterval(interval);
          resolve();
        }
      }, 10000); // Adjust the interval time as needed
    });
  }

  const onInsightsClick = () => {
    return new Promise((resolve, reject) => {
        setSelectedLabel("Insights");
        setRenderInsight1(true); // Resolve the promise
      const interval = setInterval(() => {
        if (!renderOverview) {
          clearInterval(interval);
          resolve();
        }
      }, 50000);
    });
  }


  const isPopupBlocked = () => {
    const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');

    if (checkedCheckboxes?.length > 1) {
      const popUp = window.open('', '', 'width=100,height=100,left=24,top=24,scrollbars,resizable');
      if (!popUp) {
        return true;
      }
      try {
        if (popUp.closed || typeof popUp.closed === 'undefined') {
          return true;
        }
      } catch (error) {
        return true;
      }
      popUp.close();
      return false;

    }
    return false;


  };

  var isPopupBlockerActivated = function(popupWindows) {
    let blocked = false;
    popupWindows.forEach((popupWindow) => {
      if (popupWindow) {
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          try {
            popupWindow.focus();
          } catch (e) {
            blocked = true;
          }
        } else {
          popupWindow.onload = function() {
            if (!(popupWindow.innerHeight > 0)) {
              blocked = true;
            }
          };
        }
        popupWindow.close();
      } else {
        blocked = true;
      }
    });
    return blocked;
  };

  const handleTranslate = (text, type) => {
    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardSidebarDutch", type);
      }
      default: {
        return text;
      }
    }
  }


  const finalHTML = `
    <!DOCTYPE html>
    <html lang="${langCtx.language === "Nederlands" ? "nl" : "en"}">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>${handleTranslate("HTML 5 Boilerplate", "pageTitle")}</title>
        ${resultStyle}
      </head>
      <body>
      ${htmlContent}
      </body>
    </html>
    `

  const onDownloadPDF = async() => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/generateDRReport", {
          method: "POST",
          headers: {
            "Content-Type": "text/html",
            "Accept-Language": langCtx.language === "Nederlands" ? "nl" : "en",
          },
          body: finalHTML,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const url = await response.json();
        const pdfUrl = await url.reportURL;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = true;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error)
      }
  }



  const callSequentially = async () => {
    // dashboardCtx.setIsSpin2(true)
    // await onLabelClick("My Results");
    // await onLabelClick("Parish Dashboard");
    // await onLabelClick("Leadership Dashboard");
    // await onLabelClick("Parishioner Dashboard");
    //await onInsightsClick();
    onDownloadPDF();
  }
  
  
  const tableData = [
    { label: `${handleTranslate("My Results", "my-results")}` },
    { label: `${handleTranslate("Parish Dashboard", "parish-dashboard")}` },
    { label: `${handleTranslate("Leadership Dashboard", "leadership-dashboard")}` },
    { label: `${handleTranslate("Parishioner Dashboard", "parishioner-dashboard")}` },
    { label: `${handleTranslate("Insights", "insights")}` },

  ]

  const handleCheckboxChange = (e) => {
    if (e.target.value === "My Results") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setMyResults(true)
      }
      else {
        setMyResults(false)
      }
    }
    if (e.target.value === "Parish Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setParishDashbaord(true)
      }
      else {
        setParishDashbaord(false)
      }
    }
    if (e.target.value === "Leadership Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setLeadershipDashboard(true)
      }
      else {
        setLeadershipDashboard(false)
      }
    }
    if (e.target.value === "Parishioner Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setParishionerDashBoard(true)
      }
      else {
        setParishionerDashBoard(false)
      }
    }
    if (e.target.value === "Insights") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setInsights(true)
      }
      else {
        setInsights(false)
      }
    }
    if (e.target.value === "Download All") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        const cb = document.querySelectorAll('.checkbox')
        cb.forEach((checkbox) => {
          checkbox.checked = true;
        });
        setMyResults(true)
        setParishDashbaord(true)
        setLeadershipDashboard(true)
        setParishionerDashBoard(true)
        setInsights(true)
        setdownloadAll(true)
      }
      else {
        const cb = document.querySelectorAll('.checkbox')
        cb.forEach((checkbox) => {
          checkbox.checked = false;
        });
        setMyResults(false)
        setParishDashbaord(false)
        setLeadershipDashboard(false)
        setParishionerDashBoard(false)
        setInsights(false)
        setdownloadAll(false)
      }
    }
    const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');
    setShowDownloadButton(checkedCheckboxes.length > 0);
  }

  useEffect(() => {
    if (currentLocation.includes('/overview')) {
      setTabSelected(0);
    } else if (currentLocation.includes('/compare')) {
      setTabSelected(1);
    } else if (currentLocation.includes('/insights')) {
      setTabSelected(2);
    } else if (currentLocation.includes('/resources')) {
      setTabSelected(3);
    } else {
      // Default case
    }
  }, [currentLocation]);

  const handleLogOut = () => {
    authCtx.logout();
    navigate('/login', { replace: true });
  }

  const handleNavigation = (tab, index) => {
    setTabSelected(index);
    navigate(`/dashboard/${id}/${tab}`, { replace: true });
  }

  const HideManu = (event) => {
    event.stopPropagation();
    if (slideClass === 'slide-hide' || slideClass === 'hide') {
      setSlideClass('slide-show');
    } else {
      setSlideClass('slide-hide');
    }
  }

  

  const handleRefresh = () => {
    window.location.reload();
  }

  return (
    <div className='sidebar-cover' ref={componentRef}>
      {windowWidth <= 600 ?
        <div className='manu'>
          <img className='manu-icon' src={ManuIcon} alt="" onClick={HideManu} />
          <p className='greeting'>{handleTranslate("Welcome to your dashboard!", "welcome-heading")}</p>
        </div>
        :
        <p className='greeting'>{handleTranslate("Welcome to", "welcome-to")} <br /> {handleTranslate("your dashboard!", "your-dashboard")}</p>}
      <div className={`sidebar ${slideClass}`}>
        <div className='primary-tabs'>
          <div className={`tab ${tabSelected === 0 && 'active'}`} onClick={() => handleNavigation('overview', 0)}>
            <img src={Overview} alt="overview" />
            <p>{handleTranslate("Overview", "overview")}</p>
            {windowWidth <= 600 && <img className='close-manu' src={BackArrow} onClick={HideManu} />}
          </div>
          <div className={`tab ${tabSelected === 1 && 'active'}`} onClick={() => handleNavigation('compare', 1)}>
            <img src={Compare} alt="compare" />
            <p>{handleTranslate("Compare", "compare")}</p>
          </div>
          <div className={`tab ${tabSelected === 2 && 'active'}`} onClick={() => handleNavigation('insights', 2)}>
            <img src={Insights} alt="insights" />
            <p>{handleTranslate("Insights", "insights")}</p>
          </div>
          <div className={`tab ${tabSelected === 3 && 'active'}`} onClick={() => handleNavigation('resources', 3)}>
            <img src={Resources} alt="resources" />
            <p>{handleTranslate("Resources", "resources")}</p>
          </div>
        </div>
        <div className='secondry-tabs'>
          <p className='res'>{handleTranslate("Respondents", "respondents")} - {isNaN(apiData?.counts?.parishioners + apiData?.counts?.leaders + apiData?.counts?.priests) ? 0 : apiData?.counts?.parishioners + apiData?.counts?.leaders + apiData?.counts?.priests}</p>
          <div className='tab'>
            <img src={Priest} alt="" style={{ width: "22px", paddingLeft: "0.6rem", paddingRight: "0.1rem" }} />
            {apiData?.counts?.priests <= 1 ?
              <p><strong>{apiData?.counts?.priests}</strong> {handleTranslate("Priest", "priest")}</p>
              :
              <p><strong>{apiData?.counts?.priests}</strong> {handleTranslate("Priests", "priest")}</p>
            }
          </div>
          <div className='tab'>
            <img src={Leader} alt="" />
            {apiData?.counts?.leaders <= 1 ?
              <p><strong>{apiData?.counts?.leaders}</strong> {handleTranslate("Leader", "leaders")}</p>
              :
              <p><strong>{apiData?.counts?.leaders}</strong> {handleTranslate("Leaders", "leaders")}</p>
            }
          </div>
          <div className='tab'>
            <img src={Parishioner} alt="" />
            {apiData?.counts?.parishioners <= 1 ?
              <p><strong>{apiData?.counts?.parishioners}</strong> {handleTranslate("Parishioner", "parishioners")}</p>
              :
              <p><strong>{apiData?.counts?.parishioners}</strong> {handleTranslate("Parishioners", "parishioners")}</p>
            }
          </div>
        </div>
        <div className='third-tabs'>
          <div className='tab'>
            <img src={RefreshResults} alt="" onClick={handleRefresh} />
            <p onClick={handleRefresh}>{handleTranslate("Refresh Results", "refresh-results")}</p>
          </div>
          <div className='tab'>
            <div className="circle">
              <>{<img src={Downloadicon} alt="" onClick={callSequentially} />}</>
            </div>
            {dashboardCtx.isSpin?<CircularProgress/>:<p onClick={callSequentially}>{handleTranslate("Download Results", "downlaod-results")}</p>}
          </div>
          <div className='tab' style={{ display: "none" }} onClick={handleLogOut}>
            <img src={Logout} alt="" />
            <p>{handleTranslate("Log Out", "log-out")}</p>
          </div>
        </div>
        {windowWidth > 600 &&
          <div className='logo'>
            <img className='logo' src={Logo} alt="" />
          </div>}
      </div>
      <div style={{display:"none"}} className='downloads-container'>
        <div className='downloads-right-container'>

          <h1>{handleTranslate("Download your results and insights in multiple formats to access them anytime.", "downloads-heading-info")}</h1>


          <div className='download-section'>
            <h2>{handleTranslate("Downloads", "downloads")}</h2>

            <table className='download-table'>
              <thead>
                <tr className="header">
                  <th className="center-cell">
                    <input style={{ padding: "10px 5px" }} value={`Download All`} type="checkbox" id={`DownloadAll`} className='checkbox' onChange={handleCheckboxChange} />
                  </th>
                  <th>{handleTranslate('Title', 'title')}</th>
                  <th>&nbsp;</th>
                  <th>{handleTranslate("Download Format", "format")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  tableData?.map((item, index) => (
                    <tr className='content' key={index}>
                      <th className="center-cell">
                        <input value={`${item.label}`} type="checkbox" id={`${item.label.replace(/ +/g, '')}`} className='checkbox' onChange={handleCheckboxChange} />
                      </th>
                      <th>
                        <p>{item?.label} </p>
                      </th>
                      <th>&nbsp;</th>
                      <th>
                        <div className='images-container'>
                          {((renderOverview || renderInsight1) && item.label === selectedLabel) ? "": <img style={{ display: `${item.label === "Download All" ? "none" : ""}` }} src={""} alt='' onClick={() => {
                            if (item?.label !== "Insights" && item?.label !== "Download All") {
                              onLabelClick(item?.label)
                            }
                            else if (item?.label === "Insights") {
                              onInsightsClick()
                            }
                          }} />}

                        </div>
                        {/* <img src={pptImage} alt='' />
                        <img src={pngImage} alt='' /> */}
                        {renderOverview && selectedLabel === item.label && (
                          <div style={{ display: "none" }}> 
                            <DashboardOverview
                              selectedLabel={selectedLabel}
                              shouldObserve={true}
                              setRenderOverview={setRenderOverview}
                            />
                          </div>
                        )}
                        {/*
                        <div style={{ display: "none" }}>
                          {[0,1,2].map(i=>{
                            return(renderInsight && selectedLabel === item.label && (<DashboardInsights selectedTab={i} shouldObserve={true} setRenderInsight={setRenderInsight}/>))
                          })}
                        </div>
                        */}
                        {
                          <div style={{ display: "none" }}>
                            {renderInsight1 && selectedLabel === item.label && <DashboardInsights selectedTab={0} shouldObserve={true} setRenderInsight={setRenderInsight1} />}
                          </div>
                        }
                      </th>
                    </tr>

                  ))
                }

              </tbody>
            </table>


          </div>
          <div className='download-btn'>
            {showDownloadButton && (
              <button onClick={callSequentially} id="downloadBtn" >Download</button>
            )}
          </div>


        </div>

      </div>
    </div>
  );
}

export default Sidebar;
