export const DashboardOverviewDutch = (text, type, section) => {
  switch (section) {
    case "my-parish":
      return "Mijn parochie";
    case "leaders":
      return "Leiders";
    case "parishioners":
      return "Parochianen";
    case "my-results":
      return "Mijn resultaten";
    case "my-parish-info":
      return "Bekijk hoe je parochie zichzelf ziet, waar ze denkt dat ze het goed doet en waar ze hoopt op verbetering.";
    case "leaders-info":
      return "Bekijk hoe de leiders de parochie zien, op welke punten ze denken dat het goed gaat en op welke punten ze hopen dat het beter wordt.";
    case "parishioners-info":
      return "Bekijk hoe de parochianen de parochie zien, op welke punten ze vinden dat het goed gaat en op welke punten ze hopen dat het beter wordt.";
    case "my-results-info":
      return "Kijk hoe u uw parochie ziet, waar u denkt dat ze het goed doet en waar u hoopt dat ze beter wordt."
    case "Your-parish-is-onlookers":
      return "Jouw parochie Toeschouwers"
    case "onlookers-info":
      return "Het lijkt erop dat uw parochie zich in een vroeg stadium van vernieuwing bevindt en een gezonde ontevredenheid ervaart met hoe de dingen nu zijn. Het lijkt erop dat de leiders van uw parochie, terwijl ze van een afstandje observeren, voorzichtig en tegelijkertijd geïntrigeerd zijn door de vernieuwing in andere parochies. Hoewel er misschien enige aarzeling is om te veranderen, is er ook een aantrekking tot het potentieel voor positieve transformatie. Er is een groeiend verlangen naar verandering en het geloof dat dingen anders kunnen begint wortel te schieten."
    case "your-parish-is-on-the-shore":
      return "Jouw parochie Aan wal"
    case "on-the-shore-info":
      return 'U lijkt te zien dat de parochie een fase ingaat waarin priesters en leiders erkennen dat vernieuwing mogelijk is en de moeite waard. Het lijkt erop dat de leiders van uw parochie een heilige ontevredenheid en diep verlangen naar verandering ervaren, maar meer inspiratie en aanmoediging nodig hebben om tot vernieuwing te besluiten en deze volledig te omarmen. Hun mentaliteit verschuift van "het kan anders" naar "het gaat anders worden".'
    case "your-parish-is-casting-off":
      return "Jouw parochie Van wal steken"
    case "casting-off-info":
      return "U lijkt te zien dat uw parochie actief op weg is naar vernieuwing. Hoewel nog niet alle details zijn uitgewerkt, is de beslissende keuze om te veranderen gemaakt. Op dit moment is uw parochie bezig met het vormen van een visie, het identificeren van bondgenoten en het investeren in de drie sleutels die vernieuwing in een parochie mogelijk maken."
    case "your-parish-is-raising-the-sails":
      return "Jouw parochie Het hijsen van de zeilen"
    case "raising-the-sails-info":
      return " U lijkt te zien dat uw parochie nu de vruchten plukt van eerdere veranderingen. Op dit moment lijkt het erop dat de parochie, na te hebben besloten om te gaan vernieuwen, de noodzakelijke elementen voor deze missie aan het verzamelen is. Ook al is er misschien tegenwerking en chaos, je ziet mogelijk al de eerste vruchten en levens veranderen, en hopelijk inspireren ze je om door te gaan. Het aanpakken van een ongezonde cultuur roept wat weerstand op en het besef dat verandering niet snel gaat begint door te dringen."
    case "your-parish-is-sailing-the-waves":
      return "Jouw parochie De golven bedwingen"
    case "sailing-the-waves-info":
      return " U lijkt uw parochie als bloeiend te ervaren. Het lijkt erop dat door de kracht van de Heilige Geest, het toepassen van leiderschapsprincipes en het geven van prioriteit aan evangelisatie, uw parochie actief nieuwe discipelen ‘maakt’. Terwijl u doorgaat met vrucht dragen, komt u ook nieuwe uitdagingen tegen: burn-out wordt een potentiële zorg en u ziet de noodzaak om structurele veranderingen door te voeren om de missie gaande te houden."
    case "your-parish-is-beacon-parish":
      return "Jouw parochie baken parochies"
    case "beacon-parish-info":
      return " Het lijkt erop dat uw parochie actief werkt aan een plan voor vitaliteit op de lange termijn. Het lijkt erop dat uw parochie een visie heeft ontwikkeld, een leiderschapsteam heeft gevormd en evangelisatietrajecten heeft opgezet. Dit alles maakt deel uit van een actieve parochievernieuwingsreis. Voortdurend wordt er nagedacht over structuren, processen en hulpmiddelen voor deze missie en wordt deze aangescherpt en verfijnd. Uw parochie is op weg om een invloedrijke voorloper in deze vernieuwingsbeweging te worden, die andere parochies begeleidt op deze transformatieve reis."
    case "my-results-onlookers-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als Toeschouwers"
    case "my-results-on-the-shore-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als Aan wal"
    case "my-results-casting-off-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als Van wal steken"
    case "my-results-raising-the-sails-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als Het hijsen van de zeilen"
    case "my-results-sailing-the-waves-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als De golven bedwingen"
    case "my-results-beacon-parish-heading":
      return "Het lijkt erop dat u uw parochie waarneemt als baken parochies"
    case "leaders-onlookers":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is Toeschouwers"
    case "leaders-on-the-shore":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is Aan wal"
    case "leaders-casting-off":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is Van wal steken"
    case "casting-off":
      return " U lijkt te zien dat uw parochie actief op weg is naar vernieuwing. Hoewel nog niet alle details zijn uitgewerkt, is de beslissende keuze om te veranderen gemaakt. Op dit moment is uw parochie bezig met het vormen van een visie, het identificeren van bondgenoten en het investeren in de drie sleutels die vernieuwing in een parochie mogelijk maken."
    case "leaders-raising-the-sails":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is Het hijsen van de zeilen"
    case "leaders-sailing-the-waves":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is De golven bedwingen"
    case "leaders-beacon-parish":
      return "Uw leiders schijnen te beseffen dat uw parochie dat ook is baken parochies"
    case "parishioners-onlookers":
      return "Uw parochianen lijken uw parochie te zien als: Toeschouwers"
    case "parishioners-on-the-shore":
      return "Uw parochianen lijken uw parochie te zien als: Aan wal"
    case "parishioners-casting-off":
      return "Uw parochianen lijken uw parochie te zien als: Van wal steken"
    case "parishioners-raising-the-sails":
      return "Uw parochianen lijken uw parochie te zien als: Het hijsen van de zeilen"
    case "parishioners-sailing-the-waves":
      return "Uw parochianen lijken uw parochie te zien als: De golven bedwingen"
    case "parishioners-beacon-parish":
      return "Uw parochianen lijken uw parochie te zien als: baken parochies"
    case "result-details-title":
      return "Observaties van uw sterke punten in uw parochie";
    case "result-details-title-2":
      return "Gebieden waarin je misschien wilt groeien";
    case "your-parish-collective-score":
      return "De collectieve scores van uw parochie"
    case "type-of-parishioners":
      return "Soort parochianen"
    case "qr-code-heading":
      return "Nodig meer parochianen en leiders uit om de vragenlijst in te vullen"  
    case "qr-code-heading-parishioner-zero":
      return "Nodig uw parochianen uit om de vragenlijst in te vullen"  
    case "qr-code-heading-leader":
      return "Nodig uw leiders uit om de vragenlijst in te vullen"  
    case "qr-code-sub-heading":
      return "Nodig al je parochianen en leden van het leiderschapsteam uit om de vragenlijst in te vullen en hun individuele en groepsscores te bekijken om erachter te komen waar jullie op één lijn zitten en waar nog ontwikkeling mogelijk is om jullie impact te maximaliseren."  
    case "qr-code-sub-heading-least":
      return "Zorg dat in ieder geval tien parochianen en één leider de vragenlijst invullen om toegang te krijgen tot de individuele en gezamenlijke scores van uw parochie. Dit kan u helpen om te ontdekken hoe uw parochie zichzelf ziet."  
    case "for-leaders":
      return "Voor leiders"  
    case "or":
      return "of"  
    case "copy-link-text":
      return "Kopieer link"  
    case "for-parishioners":
      return "Voor parochianen"
    case "no-data-info-line":
      return "Nodig uw parochianen en leiders uit om de vragenlijst in te vullen om hun resultaten te bekijken."
    case "ship-officer-heading":
      return "Scheepsofficier"
    case "helm-heading":
      return "Stuurman"
    case "steward-heading":
      return "Gastvrouw/man"
    case "sailor-heading":
      return "matroos"
    case "deckhand-heading":
      return "Scheepsjongen"
    case "passenger-heading":
      return "Passagier"
    case "ship-officer-info":
      return 'Net als een scheepsofficier zie je jouw leiderschapscapaciteiten als nuttig voor de reis van de parochie naar vernieuwing. Je openheid voor de Heilige Geest en je verlangen om te evangeliseren laten zien dat je op één lijn zit met de missie van de parochie om "discipelen te werven" en dat je misschien in staat bent om te dienen in verschillende programma’s.'
    case "helm-info":
      return "Net als de stuurman op een schip suggereren de manier waarop je je leiderschap ziet en je openheid voor de Heilige Geest dat je wellicht aanleg hebt om dingen in de juiste richting te sturen. Dit kan zijn ter ondersteuning van het leiderschapsteam en de visie voor de parochie, of zelfs als deel daarvan."
    case "steward-info":
      return "Net als de gastvrouw/heer op een schip, suggereert de manier waarop u kijkt naar uw  leiderschapscapaciteiten en uw verlangen naar evangelisatie dat u sterk bent in het verwelkomen van mensen en ervoor zorgt dat ze zich thuis voelen. Dit is cruciaal in een missionaire parochie. Dienen in evangelisatieteams, programma's zoals Alpha, of bij het gastvrijheidsteam van de parochie kan goed bij u passen."
    case "sailor-info":
      return "Matrozen vormen de ruggengraat van de scheepsbemanning en werken op veel verschillende gebieden. U ziet uw relatie met de Heilige Geest en uw verlangen om te evangeliseren als sterke punten die uw parochie van dienst kunnen zijn. Gebieden zoals gebedspastoraat, evangelisatieteams of programma's zoals Alpha kunnen goed bij u passen."
    case "deckhand-holy-spirit-info":
      return "Scheepsjongens zijn de bemanning van een schip. Je ziet het contact  met de Heilige Geest als een kracht die kan bijdragen aan de missie van je parochie. Vooral werken in gebedspastoraat kan goed bij je passen."
    case "deckhand-evangelization-info":
      return "Scheepsjongens zijn de bemanning van een schip. Je ziet je verlangen naar evangelisatie als een kracht die kan bijdragen aan de missie van je parochie. Vooral het werken in evangelisatieprogramma's kan goed bij je passen."
    case "deckhand-leadership-info":
      return "Scheepsjongens zijn de bemanning van een schip. Je ziet je leiderschapskwaliteiten als een kracht die kan bijdragen aan de missie van je parochie. Het organiseren van activiteiten of programma's kan goed bij je passen." 
    case "passenger-info":
      return "De parochie is actief bezig met het opstellen van een plan voor gezondheid en levensvatbaarheid op de lange termijn. De parochie heeft een visie geformuleerd, een leiderschapsteam gevormd, een evangelisatieprogramma opgezet en een leiderschapslijn ontwikkeld - dit alles maakt deel uit van een actieve vernieuwingsreis. Voortdurend wordt er nagedacht over structuren, processen en hulpmiddelen voor de missie en worden deze gesnoeid en verfijnd. Uw parochie is op weg om een invloedrijke leider van een vernieuwingsbeweging te worden, die andere parochies begeleidt in deze reis."
    case "qr-recommend":
      return "Gebruik deze QR-codes om de Missionaire wegwijzer met anderen te delen wanneer jullie samenkomen."
    case "qr-recommend-this":
      return "Gebruik deze QR-code om de Missionaire wegwijzer met een grote groep te delen."
    case "parishioners-count-zero":
      return "Nodig uw parochianen uit om de vragenlijst in te vullen om hun resultaten te zien."
    case "leader-count-zero":
      return "Nodig uw leiders uit om de vragenlijst in te vullen om hun resultaten te zien."
    case "generic-no-growth-areas":
      return "U ziet uw parochie als heel missionair en ver op weg naar vernieuwing. Uw parochie is tijdens haar groei uitdagingen tegengekomen en u bent met haar meegegroeid. Blijf uw priester en leiders aanmoedigen door voor hen te blijven bidden."
    case "generic-no-strengths":
      return "U ziet dat uw parochie nog maar net begonnen is aan haar reis naar vernieuwing. Dit is een spannende tijd, want uw mogelijkheden zijn eindeloos als uw parochie groeit. Tijd doorbrengen in gebed voor uw priester en parochie bereidt u voor op aankomende veranderingen."
      case "que-one-positive-statement":
        return "De transformerende kracht van de Heilige Geest is zichtbaar in uw parochie."
      case "que-one-negative-statement":
        return "De transformatie van een persoon of een parochie is alleen mogelijk door Gods genade en de kracht van de Heilige Geest. Zoek naar mogelijkheden om te bidden met en voor anderen om te vragen om de kracht van de Heilige Geest."
      case "que-two-positive-statement":
        return "U herkent en waardeert de gaven van de Heilige Geest in uw eigen leven en de mensen om u heen. Dit is een verrijking voor de spirituele reis van uw parochie."
      case "que-two-negative-statement":
        return "De Heilige Geest is altijd actief, maar het is ook belangrijk om oog te hebben voor wat God doet in onze levens en onze parochie. Ga actief op zoek naar Gods werk in uw leven en het leven van anderen. Benoem wat u ziet in uw leven en ga in gesprek met anderen bij wie u de Heilige Geest aan het werk ziet."
      case "que-three-positive-statement":
        return "U bent in staat om de stem van de Heilige Geest te verstaan te midden van de complexiteit van het leven. Dit is een teken van spirituele sensitiviteit in uw parochie."
      case "que-three-negative-statement":
        return "Omdat er in deze wereld zo veel stemmen vechten om onze aandacht, kan het lastig zijn om Gods stem tussen alle andere stemmen te horen. Door dagelijks de tijd te nemen om Zijn stem te verstaan en open te staan voor kleine tekenen, wordt dit na verloop van tijd gemakkelijker. Er zijn allerlei handreikingen beschikbaar om hierin te groeien, die u zelf kunt lezen of kunt delen met anderen."
      case "que-four-positive-statement":
        return "Uw toewijding om te reflecteren op tekenen van de Heilige Geest helpt uw parochie om Gods stem beter te onderscheiden."
      case "que-four-negative-statement":
        return "Het luisteren naar Gods stem is een uitdaging, zelfs als we er actief naar op zoek zijn. Het wordt bijna onmogelijk als we niet de tijd nemen specifiek te reflecteren op wat God tegen ons zegt. Het kan helpen om hier tijd voor te nemen in groepen of bijeenkomsten, om zo een cultuur te creëren waar dat de norm wordt."
      case "que-five-positive-statement":
        return "Uw toewijding om te onderscheiden tussen menselijke redeneringen en de wijsheid van de Heilige Geest zorgt voor een diepe spirituele cultuur in uw parochie."
      case "que-five-negative-statement":
        return "In ons persoonlijk leven en in het leven van onze parochie is het belangrijk om te herkennen dat God ons roept om dingen te doen die tegen ons eigen verstand in gaan. Door geen ruimte te laten voor God en alleen op onszelf te vertrouwen kan er geen transformatie plaatsvinden. Creëer mogelijkheden voor uzelf en anderen om in gebed te ontdekken of God u uitdaagt om groter of anders te denken dan wat ons eigen verstand te bieden heeft."
      case "que-six-positive-statement":
        return "Uw bereidheid om risico’s te nemen die aansluiten bij de richting die de Heilige Geest wijst inspireert en versterkt uw parochie."
      case "que-six-negative-statement":
        return "Uw reflectie suggereert dat u het onaangeboorde potentieel voor uw parochie herkent om moedig geloof te tonen door middel van opzettelijke, door de Geest geleide risico's."
      case "que-seven-positive-statement":
        return "Uw geloof komt tot uiting door risico’s te nemen onder leiding van de Heilige Geest. Dit is een bron van kracht en inspiratie voor uw parochie."
      case "que-seven-negative-statement":
        return "Het nemen van risico’s is op zichzelf niet altijd goed, maar als het aansluit bij de richting die de Heilige Geest wijst, getuigt het van moed. Als christenen zijn we geroepen om moedig te leven en te dromen, niet vanwege onze eigen verdiensten, maar omdat we vertrouwen op God."
      case "que-eight-positive-statement":
        return "Uw vertrouwen op de Heilige Geest blijkt uit hoe u met risico’s omgaat. Dit is de basis van de spirituele vitaliteit van uw parochie."
      case "que-eight-negative-statement":
        return "Elk persoon en elke parochie krijgt te maken met risico’s waar ze geen invloed op hebben. Maar als God echt centraal staat in onze missie en visie kunnen we deze risico’s met vertrouwen tegemoet treden, zonder onze identiteit te verliezen."
      case "que-nine-positive-statement":
        return "Uw toewijding aan het prioriteren van evangelisatie wanneer u plannen maakt laat zien dat uw parochie toegewijd is om het goede nieuws te verspreiden."
      case "que-nine-negative-statement":
        return "Evangelisatie is de belangrijkste missie van de kerk. Er zijn veel manieren om te evangeliseren. Het belangrijkste voor een missionaire parochie is om intentioneel te zijn over hoe de activiteiten en diensten het goede nieuws in alles verkondigen."
      case "que-ten-positive-statement":
        return "Uw voortdurende focus op het presenteren van het goede nieuws in de hele programmering laat zien dat u een passie heeft om de boodschap van Jezus te verspreiden."
      case "que-ten-negative-statement":
        return "Als we plannen maken, kunnen we overwegen hoe een bepaalde activiteit of dienst een evangelisatiemiddel is, maar het moet ook op die manier uitgevoerd worden. Wat is de beste manier om het goede nieuws te brengen in uw context?"
      case "que-eleven-positive-statement":
        return "U ziet het delen van het goede nieuws als een gezamenlijke verantwoordelijkheid. In uw parochie wordt een evangelisatiecultuur gestimuleerd."
      case "que-eleven-negative-statement":
        return "Het delen van het goede nieuws is een opdracht van Jezus. Daarnaast, als we de vreugde van het kennen van Jezus in ons eigen leven ervaren, waarom zouden we dat dan niet willen delen met anderen? Activiteiten als Alpha kunnen het gemakkelijker maken om Christus te delen met anderen, maar dit betekent wel dat iedereen anderen moet uitnodigen."
      case "que-twelve-positive-statement":
        return "U biedt mogelijkheden aan anderen om te reageren op het goede nieuws. Dit is een teken van toewijding aan de spirituele groei en transformatie van uw parochie."
      case "que-twelve-negative-statement":
        return "Een activiteit als Alpha kan veel doen om de ontmoeting met Christus te normaliseren. Maar het kan niet een geïsoleerde activiteit blijven. Samen bidden voor de deelnemers, elkaar ontmoeten en de actieve bijdrage van verschillende parochianen helpt om dit een vast onderdeel van het parochieleven te maken."
      case "que-thirteen-positive-statement":
        return "Uw bereidheid om mogelijkheden voor groei in leiderschapskwaliteiten te erkennen laat zien dat u een nederige en reflectieve benadering hanteert om uw parochie te dienen."
      case "que-thirteen-negative-statement":
        return "Vernieuwing kan alleen gebeuren als de gaven en talenten van parochianen worden ingezet. Blijven zoeken naar mogelijkheden, ook als ze niet vanzelfsprekend lijken, kan u en anderen helpen om het vernieuwingsproces aan te gaan."
      case "que-fourteen-positive-statement":
        return "Uw zelfbewustzijn wat betreft uw leiderschapskwaliteiten en groeimogelijkheden weerspiegelt uw toewijding aan continue verbetering en effectief leiderschap in uw parochie."
      case "que-fourteen-negative-statement":
        return "Er zijn verschillende hulpmiddelen die helpen om uw gaven en talenten te ontdekken, die ontwikkeld kunnen worden om te groeien als leider. Als u dat nog niet heeft gedaan, kunt u een APEST test of een StrengthsFinder invullen."
    default:
      return text;
  }
}